// Auth.js
import './Auth.scss';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { setAppTheme } from '../../storeRedux';
import logo from '../../assets/logos/logo.svg';
import { jwtDecode } from 'jwt-decode';
import AnimatedBackground from './AnimatedBackground';
import { setUserRole } from '../../storeRedux';
import { setProvider } from '../../storeRedux';
import { setUserName } from '../../storeRedux';
import { setUserID } from '../../storeRedux';
import { setUserRoleIncludeBoost } from '../../storeRedux';
import { setUserCode } from '../../storeRedux';

export default function Auth() {
    const dispatch = useDispatch();
    const api_url = useSelector((state) => state.API_URL);
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    const handleLoginChange = (event) => setUsername(event.target.value);
    const handlePasswordChange = (event) => setPassword(event.target.value);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin({ username, password });
        }
    };

    const setReduxUserRole = (some_role) => {
        const newData = some_role;
        dispatch(setUserRole(newData));
    };

    const setReduxProvider = (some_provider) => {
        const newData = some_provider;
        dispatch(setProvider(newData));
    };

    const setReduxUserName = (username) => {
        const newData = username;
        dispatch(setUserName(newData));
    };

    const setUserIDFunc = (some_data) => {
        const newData = some_data;
        dispatch(setUserID(newData));
    };

    const setReduxUserCode = (some_code) => {
        const newData = some_code;
        dispatch(setUserCode(newData));
    };
    
    const setReduxUserRoleIncludesBoost = (some_role) => {
        const newData = some_role;
        dispatch(setUserRoleIncludeBoost(newData));
    };

    const handleLogin = async ({ username, password }) => {
        const request = new Request(api_url + 'token/', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
    
        const response = await fetch(request);
        if (response.status < 200 || response.status >= 300) {
            setError(true);
            throw new Error(response.statusText);
        }
    
        const data = await response.json();

        const { access, refresh } = data;
    
        // Декодирование access токена
        const decodedAccessToken = jwtDecode(access);
        console.log('Decoded Access Token:', decodedAccessToken); // Выводим декодированные данные

        if (decodedAccessToken.groups.includes('Оператор')) {
            setReduxUserRole('operator');
        }

        if (decodedAccessToken.groups.includes('Обменник')) {
            setReduxUserRole('partner');
        }

        if (decodedAccessToken.groups.includes('BOOST')) {
            setReduxUserRoleIncludesBoost('yes');
        } else {
            setReduxUserRoleIncludesBoost('no');
        }

        setReduxProvider(decodedAccessToken.provider);
        setReduxUserName(decodedAccessToken.username);
        setReduxUserCode(decodedAccessToken.code);
        setUserIDFunc(decodedAccessToken.user_id);

        
        // Сохраняем токены
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);
        navigate('/');
    };
    
    
    return (
        <div className="Auth">
            <AnimatedBackground />
            <img src={logo} alt="logo" />
            <div className="block">
                <h2>Авторизация</h2>
                <input 
                    type="text" 
                    placeholder={'Логин'} 
                    value={username} 
                    onChange={handleLoginChange} 
                    onClick={() => setError(false)}
                    onKeyPress={handleKeyPress} // Добавили отслеживание нажатия клавиш
                />
                <input 
                    type="password" 
                    placeholder={'Пароль'} 
                    value={password} 
                    onChange={handlePasswordChange} 
                    onClick={() => setError(false)}
                    onKeyPress={handleKeyPress} // Добавили отслеживание нажатия клавиш
                />
                {error && <span>Неправильный логин / пароль</span>}
                <button onClick={() => handleLogin({username, password})}>Войти</button>
            </div>
        </div>
    );
}
