import Sidebar from '../../components/Sidebar/Sidebar';
import './Penalties.scss';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import UserInfo from '../../components/UserInfo/UserInfo';
import { format, formatDate } from 'date-fns';
import BtnGroupHeader from '../../components/BtnGroupHeader/BtnGroupHeader';
import { useSelector, useDispatch } from 'react-redux';
import { setAppTheme } from '../../storeRedux';
import Alert from '@mui/material/Alert';

export default function Penalties() {

    localStorage.setItem('page', '/penalties');

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.APP_THEME);
    const api_url = useSelector((state) => state.API_URL);
    const show_sidebar = useSelector((state) => state.SHOW_SIDEBAR);

    const jwtToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();
    const [penaltiesData, setPenaltiesData] = useState([]);
    const [errorFetchingPenalties, setErrorFetchingPenalties] = useState(false); // стейт для вывода алерта об ошибки запроса данных реквизитов

    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const [reqRowsCount, setReqRowsCount] = useState(0);

    useEffect(() => {
        if (!jwtToken) {
            navigate('/auth');
            localStorage.clear();
        }
    }, []);

    const statusesDefault = {
        "1" : "Создан",
        "2" : "Списан"
    };

    const statusesDefaultClass = {
        "1" : "created",
        "2" : "done"
    };

    useEffect(() => {
        const jwtToken = localStorage.getItem('accessToken');
        const getPenaltiesData = async () => {
          try {
            const response = await fetch(api_url + `hub/penaltyuser/info?page=${pageIndex}&page_size=${pageSize}`, {
              headers: {
                'Authorization': `JWT ${jwtToken}`,
                'Content-Type': 'application/json'
              }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    // Обработка ошибки 401
                    localStorage.clear();
                    navigate('/auth');
                }
            }
            const data = await response.json();
            console.log(data);
            setPenaltiesData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
            setErrorFetchingPenalties(true);
          }
        };
    
        getPenaltiesData();

        const intervalId = setInterval(getPenaltiesData, 20000);

        return () => clearInterval(intervalId);
    }, [pageSize, pageIndex]);

    return (
        <div className={theme === 'light' ? "Penalties" : "Penalties dark"}>
            {errorFetchingPenalties && <Alert style={{cursor: "pointer", position: "absolute", margin: "auto", top: "16px", left: "0", right: "0", width: "max-content", zIndex: "100"}} severity="error" onClose={() => setErrorFetchingPenalties(false)}>Ошибка получения данных о штрафах</Alert>}
            <Sidebar/>
            <div className={show_sidebar ? "content closed" : "content"}>
                <div className="header">
                    <h2 className='urbanist-500'>Штрафы</h2>
                    <UserInfo/>
                    <BtnGroupHeader/>
                </div>
                <div className="pag_panel">
                    <div className="pags">
                        <button onClick={() => setPageIndex(pageIndex > 1 ? (pageIndex - 1) : (pageIndex))}>Назад</button>
                        <span>{pageIndex}</span>
                        <button onClick={() => setPageIndex(Math.ceil((reqRowsCount / pageSize)) > pageIndex ? (pageIndex + 1) : (pageIndex))}>Вперед</button>
                    </div>
                    <div className="pagSizes">
                        <button onClick={() => setPageSize(5)} className={pageSize === 5 ? 'selected' : ''}>5</button>
                        <button onClick={() => setPageSize(10)} className={pageSize === 10 ? 'selected' : ''}>10</button>
                        <button onClick={() => setPageSize(15)} className={pageSize === 15 ? 'selected' : ''}>15</button>
                    </div>
                </div>
                <div className="cards_wrapper">
                    {penaltiesData.results?.map((item, index) => (
                        <><div className={theme === 'dark' ? "CardForPenalties dark" : "CardForPenalties"} key={index}>
                            <div className={`title ${statusesDefaultClass[item.status.value]}`}>
                                <span>{statusesDefault[item.status.value]}</span>
                            </div>
                            <div className="info">
                                <div className="block">
                                    <h4>Пользователь</h4>
                                    <span>{item.user?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма списания</h4>
                                    <span>{item.amount?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма штрафа, руб</h4>
                                    <span>{item.rur?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Дата создания</h4>
                                    <span>{item.created?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Комментарий</h4>
                                    <span>{item.comment?.value}</span>
                                </div>
                            </div>
                        </div>
                        <div className={theme === 'dark' ? "CardForPenalties dark" : "CardForPenalties"} key={index}>
                            <div className={`title ${statusesDefaultClass[item.status.value]}`}>
                                <span>{statusesDefault[item.status.value]}</span>
                            </div>
                            <div className="info">
                                <div className="block">
                                    <h4>Пользователь</h4>
                                    <span>{item.user?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма списания</h4>
                                    <span>{item.amount?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма штрафа, руб</h4>
                                    <span>{item.rur?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Дата создания</h4>
                                    <span>{item.created?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Комментарий</h4>
                                    <span>{item.comment?.value}</span>
                                </div>
                            </div>
                        </div>
                        <div className={theme === 'dark' ? "CardForPenalties dark" : "CardForPenalties"} key={index}>
                            <div className={`title ${statusesDefaultClass[item.status.value]}`}>
                                <span>{statusesDefault[item.status.value]}</span>
                            </div>
                            <div className="info">
                                <div className="block">
                                    <h4>Пользователь</h4>
                                    <span>{item.user?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма списания</h4>
                                    <span>{item.amount?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма штрафа, руб</h4>
                                    <span>{item.rur?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Дата создания</h4>
                                    <span>{item.created?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Комментарий</h4>
                                    <span>{item.comment?.value}</span>
                                </div>
                            </div>
                        </div>
                        <div className={theme === 'dark' ? "CardForPenalties dark" : "CardForPenalties"} key={index}>
                            <div className={`title ${statusesDefaultClass[item.status.value]}`}>
                                <span>{statusesDefault[item.status.value]}</span>
                            </div>
                            <div className="info">
                                <div className="block">
                                    <h4>Пользователь</h4>
                                    <span>{item.user?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма списания</h4>
                                    <span>{item.amount?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма штрафа, руб</h4>
                                    <span>{item.rur?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Дата создания</h4>
                                    <span>{item.created?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Комментарий</h4>
                                    <span>{item.comment?.value}</span>
                                </div>
                            </div>
                        </div>
                        <div className={theme === 'dark' ? "CardForPenalties dark" : "CardForPenalties"} key={index}>
                            <div className={`title ${statusesDefaultClass[item.status.value]}`}>
                                <span>{statusesDefault[item.status.value]}</span>
                            </div>
                            <div className="info">
                                <div className="block">
                                    <h4>Пользователь</h4>
                                    <span>{item.user?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма списания</h4>
                                    <span>{item.amount?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма штрафа, руб</h4>
                                    <span>{item.rur?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Дата создания</h4>
                                    <span>{item.created?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Комментарий</h4>
                                    <span>{item.comment?.value}</span>
                                </div>
                            </div>
                        </div>
                        <div className={theme === 'dark' ? "CardForPenalties dark" : "CardForPenalties"} key={index}>
                            <div className={`title ${statusesDefaultClass[item.status.value]}`}>
                                <span>{statusesDefault[item.status.value]}</span>
                            </div>
                            <div className="info">
                                <div className="block">
                                    <h4>Пользователь</h4>
                                    <span>{item.user?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма списания</h4>
                                    <span>{item.amount?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма штрафа, руб</h4>
                                    <span>{item.rur?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Дата создания</h4>
                                    <span>{item.created?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Комментарий</h4>
                                    <span>{item.comment?.value}</span>
                                </div>
                            </div>
                        </div>
                        <div className={theme === 'dark' ? "CardForPenalties dark" : "CardForPenalties"} key={index}>
                            <div className={`title ${statusesDefaultClass[item.status.value]}`}>
                                <span>{statusesDefault[item.status.value]}</span>
                            </div>
                            <div className="info">
                                <div className="block">
                                    <h4>Пользователь</h4>
                                    <span>{item.user?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма списания</h4>
                                    <span>{item.amount?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма штрафа, руб</h4>
                                    <span>{item.rur?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Дата создания</h4>
                                    <span>{item.created?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Комментарий</h4>
                                    <span>{item.comment?.value}</span>
                                </div>
                            </div>
                        </div>
                        <div className={theme === 'dark' ? "CardForPenalties dark" : "CardForPenalties"} key={index}>
                            <div className={`title ${statusesDefaultClass[item.status.value]}`}>
                                <span>{statusesDefault[item.status.value]}</span>
                            </div>
                            <div className="info">
                                <div className="block">
                                    <h4>Пользователь</h4>
                                    <span>{item.user?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма списания</h4>
                                    <span>{item.amount?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма штрафа, руб</h4>
                                    <span>{item.rur?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Дата создания</h4>
                                    <span>{item.created?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Комментарий</h4>
                                    <span>{item.comment?.value}</span>
                                </div>
                            </div>
                        </div>
                        <div className={theme === 'dark' ? "CardForPenalties dark" : "CardForPenalties"} key={index}>
                            <div className={`title ${statusesDefaultClass[item.status.value]}`}>
                                <span>{statusesDefault[item.status.value]}</span>
                            </div>
                            <div className="info">
                                <div className="block">
                                    <h4>Пользователь</h4>
                                    <span>{item.user?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма списания</h4>
                                    <span>{item.amount?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма штрафа, руб</h4>
                                    <span>{item.rur?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Дата создания</h4>
                                    <span>{item.created?.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Комментарий</h4>
                                    <span>{item.comment?.value}</span>
                                </div>
                            </div>
                        </div></>
                    ))}
                </div>
            </div>
        </div>
    )
}