import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';
import store from './storeRedux';

import Auth from './pages/Auth/Auth';
import Requests from './pages/Requests/Requests';
import Requisites from './pages/Requisites/Requisites';
import Penalties from './pages/Penalties/Penalties';
import AddBalance from './pages/AddBalance/AddBalance';
import Settings from './pages/Settings/Settings';
import AddReq from './pages/AddReq/AddReq';
import EditReq from './pages/EditReq/EditReq';
import AddBal from './pages/AddBal/AddBal';
import Statistics from './pages/Statistics/Statistics';
import Withdrawals from './pages/Withdrawals/Withdrawals';
import ChartsOp from './pages/ChartsOp/ChartsOp';

import PrivateRoute from './components/PrivateRoute/PrivateRoute';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  ruRU,
);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className="App assistant-400">
          <BrowserRouter>
            <Routes>
              {/* Доступные всем */}
              <Route path="/auth" element={<Auth />} />

              {/* Маршруты для operator */}
              <Route
                path="/"
                element={
                  <PrivateRoute allowedRoles={['operator', 'partner']}>
                    <Requests />
                  </PrivateRoute>
                }
              />
              <Route
                path="/requisites"
                element={
                  <PrivateRoute allowedRoles={['operator']}>
                    <Requisites />
                  </PrivateRoute>
                }
              />
              <Route
                path="/penalties"
                element={
                  <PrivateRoute allowedRoles={['operator']}>
                    <Penalties />
                  </PrivateRoute>
                }
              />
              <Route
                path="/addbalance"
                element={
                  <PrivateRoute allowedRoles={['operator']}>
                    <AddBalance />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <PrivateRoute allowedRoles={['operator']}>
                    <Settings />
                  </PrivateRoute>
                }
              />
              <Route
                path="/addreq"
                element={
                  <PrivateRoute allowedRoles={['operator']}>
                    <AddReq />
                  </PrivateRoute>
                }
              />
              <Route
                path="/editreq"
                element={
                  <PrivateRoute allowedRoles={['operator']}>
                    <EditReq />
                  </PrivateRoute>
                }
              />
              <Route
                path="/addbal"
                element={
                  <PrivateRoute allowedRoles={['operator']}>
                    <AddBal />
                  </PrivateRoute>
                }
              />
              <Route
                path="/chartsop"
                element={
                  <PrivateRoute allowedRoles={['operator']}>
                    <ChartsOp />
                  </PrivateRoute>
                }
              />

              {/* Маршрут для partner */}
              <Route
                path="/statistics"
                element={
                  <PrivateRoute allowedRoles={['partner']}>
                    <Statistics />
                  </PrivateRoute>
                }
              />
              <Route
                path="/withdrawals"
                element={
                  <PrivateRoute allowedRoles={['partner']}>
                    <Withdrawals />
                  </PrivateRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
