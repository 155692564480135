import './BoostButton.scss';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setNextTimeOfBoostAccount } from '../../storeRedux';

export default function BoostButton() {
    const dispatch = useDispatch();
    
    const api_url_no_ra = useSelector((state) => state.API_URL_NO_RA);
    const next_time = useSelector((state) => state.BOOST_ACCOUNT_NEXT_TIME);

    const [boostAlert, setBoostAlert] = useState('');
    const [boostIsWorking, setBoostIsWorking] = useState(false);
    const [timeLeft, setTimeLeft] = useState("00:00:00");

    const setNextTimeToBoost = (some_data) => {
        dispatch(setNextTimeOfBoostAccount(some_data));
    };

    const calculateTimeLeft = () => {
        const now = Math.floor(Date.now() / 1000);
        const nextTimestamp = Math.floor(new Date(next_time).getTime() / 1000);
        const difference = nextTimestamp - now;

        if (difference <= 0) {
            setNextTimeToBoost("");
            setBoostAlert("Буст режим завершён");
            return "00:00:00";
        }

        const hours = Math.floor(difference / 3600);
        const minutes = Math.floor((difference % 3600) / 60);
        const seconds = difference % 60;

        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    };

    useEffect(() => {
        setTimeLeft(calculateTimeLeft());

        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [next_time]);

    useEffect(() => {
        const now = Math.floor(Date.now() / 1000);
        const nextTimestamp = Math.floor(new Date(next_time).getTime() / 1000);
        setBoostIsWorking(nextTimestamp > now);
    }, [next_time]);

    const startBoostMode = async () => {
        const jwtToken = localStorage.getItem("accessToken");

        try {
            const response = await fetch(api_url_no_ra + 'api/v3/user/boost/', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `JWT ${jwtToken}`,
                },
            });

            const data = await response.json();

            switch (response.status) {
                case 200:
                    setNextTimeToBoost(data.next_allowed_time);
                    setBoostAlert(data.message);
                    break;
                case 403:
                    setBoostAlert(data.message);
                    break;
                case 402:
                    setBoostAlert(data.message);
                    break;
                case 409:
                    setBoostAlert(data.message);
                    setNextTimeToBoost(data.next_allowed_time);
                    break;
                default:
                    alert("Неожиданный ответ: " + response.status + " " + data.message);
            }
        } catch (error) {
            console.error("Ошибка при запуске буста:", error);
        }
    };

    useEffect(() => {
        if (boostAlert !== '') {
            const timer = setTimeout(() => {
                setBoostAlert('');
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [boostAlert]);

    return (
        <button className={boostIsWorking ? 'BoostButton gray' : 'BoostButton'} onClick={startBoostMode}>
            {boostAlert !== '' && <span className="BoostButton__alert">{boostAlert}</span>}
            <svg width="28px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_429_11033)">
                    <path d="M11 14H6L9.5 2H16L13 10H18L10 22L11 14Z" stroke="yellow" stroke-width="2" stroke-linejoin="round"></path>
                </g>
                <defs>
                    <clipPath id="clip0_429_11033">
                        <rect width="24" height="24" fill="white"></rect>
                    </clipPath>
                </defs>
            </svg>
            <span>{next_time !== '' ? timeLeft : 'Boost'}</span>
        </button>
    );
}
