import Sidebar from '../../components/Sidebar/Sidebar';
import { useState, useEffect } from 'react';
import './Requests.scss';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Alert from '@mui/material/Alert';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';
import UserInfo from '../../components/UserInfo/UserInfo';
import BtnGroupHeader from '../../components/BtnGroupHeader/BtnGroupHeader';
import { useSelector, useDispatch } from 'react-redux';
import { setAppTheme } from '../../storeRedux';

Modal.setAppElement('#root');
const ConfirmationModal = ({ isOpen, onRequestClose, onConfirm, price, textLabel, textAction }) => {

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel={textLabel}
            className="confirmation-modal"
            overlayClassName="confirmation-modal-overlay"
        >
            <h2 className="confirmation-modal-title">{textLabel}</h2>
            <div className="confirmation-modal-content">
                <p dangerouslySetInnerHTML={{ __html: `Вы уверены, что хотите ${textAction} заявку с суммой ${price} рублей?` }}></p>
            </div>
            <div className="confirmation-modal-actions">
                <button className="confirmation-modal-button confirm" onClick={onConfirm}>Да</button>
                <button className="confirmation-modal-button cancel" onClick={onRequestClose}>Нет</button>
            </div>
        </Modal>
    );
};

export default function Requests() {

    localStorage.setItem('page', '/');

    const [requestsData, setRequestsData] = useState([]);

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.APP_THEME);
    const api_url = useSelector((state) => state.API_URL);
    const show_sidebar = useSelector((state) => state.SHOW_SIDEBAR);

    //const [requestsData, setRequestsData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [modalIsOpen1, setModalIsOpen1] = useState(false); // закрыть заявку
    const [modalIsOpen2, setModalIsOpen2] = useState(false); // отменить заявку
    const [modalIsOpen3, setModalIsOpen3] = useState(false); // закрыть по видео
    const [selectedRequestPrice, setSelectedRequestPrice] = useState('');
    const [selectedRequestPk, setSelectedRequestPk] = useState('');
    const [indexOfChanged, setIndexOfChanged] = useState([]);

    const [searchNumber, setSearchNumber] = useState('');
    // const [priceFrom, setPriceFrom] = useState('');
    // const [priceTo, setPriceTo] = useState('');
    const [selectedButtons, setSelectedButtons] = useState([]);
    const [statusValues, setStatusValues] = useState([]);
    // Сверху 5 стейтов для фильтров

    const [errorFetchingRequests, setErrorFetchingRequests] = useState(false); // стейт для вывода алерта об ошибки запроса данных заявок
    const [errorClosingReq, setErrorClosingReq] = useState(false); // стейт для вывода алерта об ошибки закрытия заявки
    const [closingReqText, setClosingReqText] = useState(''); // стейт для вывода текста закрытия заявки 
    const [closingReqIsDone, setClosingReqIsDone] = useState(false);

    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const [reqRowsCount, setReqRowsCount] = useState(0);

    const [isReqInCheck, setIsReqInCheck] = useState(false);

    const [activeReqCount, setActiveReqCount] = useState(0);

    const [inCheckCount, setInCheckCount] = useState(0);

    const statusesDefault = {
        "new" : "новая",
        "payment_wait" :"ожидает оплаты",
        "payed" : "оплачено",
        "need_success" : "в очереди на завершение",
        "trouble" : "открыт спор",
        "changed" : "изменена сумма заявки",
        "success" : "успешно завершено",
        "canceled" : "отмена",
        "autocanceled" : "автоотмена", 
        "success_by_support": "завершено поддержкой"
    };

    const handleButtonClick = (button) => {
        const buttonValuesMap = {
            'Активно': ['new', 'payment_wait', 'changed'],
            'Успешно завершено': ['payed', 'success', 'need_success'],
            'Отмена': ['autocanceled', 'canceled'],
            'Завершено поддержкой': ['success_by_support']
        };

        const buttonValues = buttonValuesMap[button];

        if (selectedButtons.includes(button)) {
            // Убираем кнопку из selectedButtons и её значения из statusValues
            setSelectedButtons(selectedButtons.filter((item) => item !== button));
            setStatusValues(statusValues.filter((item) => !buttonValues.includes(item)));
        } else {
            // Добавляем кнопку в selectedButtons и её значения в statusValues
            setSelectedButtons([...selectedButtons, button]);
            setStatusValues([...statusValues, ...buttonValues]);
        }
    };


    

    const generateColumns = (data) => {
        if (data.length > 0) {
            const firstItem = data[0];
            const generatedColumns = Object.keys(firstItem).slice(1).map(key => ({
                id: key,
                label: firstItem[key]?.label || key,
                minWidth: 170,
                align: 'left',
                format: (value) => <div dangerouslySetInnerHTML={{ __html: value }} />,
                formatStatus: (value) => <div className={`statusCell ${value}`}>{statusesDefault[value]}</div>,
            }));
            setColumns(generatedColumns);
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        const jwtToken = localStorage.getItem('accessToken');
        if (!jwtToken) {
            navigate('/auth');
            localStorage.clear();
        }
    }, []);

    const [rows, setRows] = useState(requestsData.map(createDataFromRequest));

    useEffect(() => {
        const jwtToken = localStorage.getItem('accessToken');
        const getRequestsData = async () => {
            try {
                const response = await fetch(api_url + `hub/exchangerequest/info/?page=${pageIndex}&page_size=${pageSize}&status__in=${statusValues.join(',')}&search=${searchNumber}${isReqInCheck ? '&is_under_verification=1' : ''}`, {
                    headers: {
                        'Authorization': `JWT ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) {
                    if (response.status === 401) {
                        // Обработка ошибки 401
                        localStorage.clear();
                        navigate('/auth');
                    }
                }
                const data = await response.json();
                console.log(data);
                setRequestsData(data.results);
                setRows(data.results.map(createDataFromRequest));
                setReqRowsCount(data.count);

                //Генерация колонок 
                generateColumns(data.results); // requestsData - заменить на data

                // Обработка индексов измененных элементов
                const changedIndexes = data.results.map((element, index) => element.status.value === 'changed' ? index : null).filter(index => index !== null); // requestsData - заменить на data
                setIndexOfChanged(changedIndexes);
            } catch (error) {
                console.error('Error fetching data:', error);
                setErrorFetchingRequests(true);
            }
        };

        getRequestsData();

        const intervalId = setInterval(getRequestsData, 20000);

        return () => clearInterval(intervalId);
    }, [pageIndex, pageSize, statusValues, searchNumber, isReqInCheck, modalIsOpen1, modalIsOpen2, modalIsOpen3]);

    useEffect(() => {
        const jwtToken = localStorage.getItem('accessToken');
        
        const fetchData = async () => {
            try {
                const statusFilter = ['new', 'payment_wait', 'changed'].join(',');
                const response = await fetch(`${api_url}hub/exchangerequest/info/?status__in=${statusFilter}`, {
                    headers: {
                        'Authorization': `JWT ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const data = await response.json();
                if (data && typeof data.count === 'number') {
                    setActiveReqCount(data.count);
                }
            } catch (error) {
                console.error('Ошибка при выполнении запроса:', error);
            }
        };

        const intervalId = setInterval(fetchData, 20000);
    
        fetchData();
    
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const jwtToken = localStorage.getItem('accessToken');
        
        const fetchData = async () => {
            try {
                const response = await fetch(`${api_url}hub/exchangerequest/info/?is_under_verification=1`, {
                    headers: {
                        'Authorization': `JWT ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const data = await response.json();
                if (data && typeof data.count === 'number') {
                    setInCheckCount(data.count);
                }
            } catch (error) {
                console.error('Ошибка при выполнении запроса:', error);
            }
        };

        const intervalId = setInterval(fetchData, 20000);
    
        fetchData();
    
        return () => clearInterval(intervalId);
    }, []);
    
    
    function createDataFromRequest(request) {
        let data = {};
        for (let key in request) {
            if (request.hasOwnProperty(key)) {
                data[key] = request[key].value;
            }
        }
        return data;
    }

    function closeRequest() {
        const jwtToken = localStorage.getItem('accessToken');
        fetch(`/api/v3/exchange_request/close/${selectedRequestPk}/`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (!response.ok) {
                setErrorClosingReq(true);
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setClosingReqIsDone(true);
            setClosingReqText(data.text);
            // Обновите состояние или выполните другие действия после успешного закрытия заявки
        })
        .catch(error => {
            console.error('Error closing request:', error);
            // Обработайте ошибку, если запрос завершился неудачно
        })
        .finally(() => {
            setModalIsOpen1(false);
        });
    }

    function cancelRequest() {
        const jwtToken = localStorage.getItem('accessToken');
        fetch(`/api/v3/exchange_request/cancel/${selectedRequestPk}/`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (!response.ok) {
                setErrorClosingReq(true);
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setClosingReqIsDone(true);
            setClosingReqText(data.text);
            // Обновите состояние или выполните другие действия после успешного закрытия заявки
        })
        .catch(error => {
            console.error('Error closing request:', error);
            // Обработайте ошибку, если запрос завершился неудачно
        })
        .finally(() => {
            setModalIsOpen2(false);
        });
    }

    function closeRequestByVideo() {
        const jwtToken = localStorage.getItem('accessToken');
        fetch(`/api/v3/exchange_request/close-by-video/${selectedRequestPk}/`, {
            method: 'POST',
            headers: {
                'Authorization': `JWT ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (!response.ok) {
                setErrorClosingReq(true);
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setClosingReqIsDone(true);
            setClosingReqText(data.text);
            // Обновите состояние или выполните другие действия после успешного закрытия заявки
        })
        .catch(error => {
            console.error('Error closing request:', error);
            // Обработайте ошибку, если запрос завершился неудачно
        })
        .finally(() => {
            setModalIsOpen3(false);
        });
    }
    


    // useEffect(() => {
    //     var updatedRows = requestsData.map(createDataFromRequest);
    
    //     // Условие 1: statusValues.length === 0 и searchNumber.length === 0
    //     if (statusValues.length === 0 && searchNumber.length === 0) {
    //       setRows(updatedRows);
    //     }
    //     // Условие 2: statusValues.length !== 0 и searchNumber.length === 0
    //     else if (statusValues.length !== 0 && searchNumber.length === 0) {
    //       updatedRows = updatedRows.filter(row => statusValues.includes(row.status));
    //       setRows(updatedRows);
    //     }
    //     // Условие 3: statusValues.length === 0 и searchNumber.length !== 0
    //     else if (statusValues.length === 0 && searchNumber.length !== 0) {
    //       updatedRows = updatedRows.filter(row =>
    //         findRequestsPkSubstring(row.uuid_info, '<span id="uuid_', '">').includes(searchNumber) ||
    //         Number(searchNumber) === Number(findRequestsPkSubstring(row.rur_info, '<b style="font-size: 1.2em">', '</b>'))
    //       );
    //       setRows(updatedRows);
    //     }
    //     // Условие 4: statusValues.length !== 0 и searchNumber.length !== 0
    //     else if (statusValues.length !== 0 && searchNumber.length !== 0) {
    //       updatedRows = updatedRows.filter(row =>
    //         statusValues.includes(row.status) &&
    //         (findRequestsPkSubstring(row.uuid_info, '<span id="uuid_', '">').includes(searchNumber) ||
    //           Number(searchNumber) === Number(findRequestsPkSubstring(row.rur_info, '<b style="font-size: 1.2em">', '</b>')))
    //       );
    //       setRows(updatedRows);
    //     }
    //   }, [searchNumber, statusValues]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function findRequestsPkSubstring(inputString, start, end) {
        const startIndex = inputString.indexOf(start) + start.length;
        const endIndex = inputString.indexOf(end, startIndex);
    
        if (startIndex < start.length || endIndex === -1) {
            return 'null';
        }
    
        return inputString.substring(startIndex, endIndex);
    }

    const handleClearFilters = () => {
        setSearchNumber('');
        // setPriceFrom('');
        // setPriceTo('');
        setSelectedButtons([]);
        setStatusValues([]);
    }

    return (
        <div className={theme === 'light' ? "Requests" : "Requests dark"}>
            {errorFetchingRequests && <Alert style={{cursor: "pointer", position: "absolute", margin: "auto", top: "16px", left: "0", right: "0", width: "max-content", zIndex: "100"}} severity="error" onClose={() => setErrorFetchingRequests(false)}>Ошибка получения данных о заявках</Alert>}
            {errorClosingReq && <Alert style={{cursor: "pointer", position: "absolute", margin: "auto", top: "16px", left: "0", right: "0", width: "max-content", zIndex: "100"}} severity="error" onClose={() => setErrorClosingReq(false)}>Ошибка закрытия заявки</Alert>}
            {closingReqIsDone && <Alert style={{cursor: "pointer", position: "absolute", margin: "auto", top: "16px", left: "0", right: "0", width: "max-content", zIndex: "100"}} severity="success" onClose={() => setClosingReqIsDone(false)}>{closingReqText}</Alert>}
            <Sidebar/> 
            <div className={show_sidebar ? "content closed" : "content"}>
                <div className="header">
                    <h2 className='urbanist-500'>Заявки</h2>
                    <UserInfo/>
                    <BtnGroupHeader/>
                </div>
                <div className="filters">
                    {theme === 'light' ? 
                    <div className="statuses">
                        {['Активно', 'Успешно завершено', 'Отмена', 'Завершено поддержкой'].map((status) => (
                        <button 
                            key={status}
                            className={selectedButtons.includes(status) ? 'selected' : ''}
                            onClick={() => handleButtonClick(status)}
                        >
                            {status}
                            <span style={{marginLeft: '4px', fontWeight: 'bold'}}>{status === 'Активно' ? activeReqCount : ''}</span>
                        </button>
                    ))}
                    </div>
                    :
                    <div className="statuses">
                        {['Активно', 'Успешно завершено', 'Отмена', 'Завершено поддержкой'].map((status) => (
                        <button 
                            key={status}
                            className={selectedButtons.includes(status) ? 'darkselected' : ''}
                            onClick={() => handleButtonClick(status)}
                        >
                            {status}
                            <span style={{marginLeft: '4px', fontWeight: 'bold'}}>{status === 'Активно' ? activeReqCount : ''}</span>
                        </button>
                    ))}
                    </div>}
                    <input 
                        type="text" 
                        placeholder='Поиск по id и сумме' 
                        value={searchNumber} 
                        onChange={(e) => setSearchNumber(e.target.value)}
                    />
                    <button className='clear_filteres' onClick={handleClearFilters}>
                        <span>Сбросить фильтры</span>
                        <svg width="28" height="28" viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg" version="1.1" baseProfile="full" enableBackground="new 0 0 76.00 76.00" fill="#000000">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"/>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>
                            <g id="SVGRepo_iconCarrier"> <path fill="#000000" fillOpacity="1" strokeWidth="0.2" strokeLinejoin="round" d="M 47.5282,42.9497L 42.5784,38L 47.5282,33.0502L 44.9497,30.4718L 40,35.4216L 35.0502,30.4718L 32.4718,33.0502L 37.4216,38L 32.4718,42.9497L 35.0502,45.5282L 40,40.5784L 44.9497,45.5282L 47.5282,42.9497 Z M 18.0147,41.5355L 26.9646,50.4854C 28.0683,51.589 29,52 31,52L 52,52C 54.7614,52 57,49.7614 57,47L 57,29C 57,26.2386 54.7614,24 52,24L 31,24C 29,24 28.0683,24.4113 26.9646,25.5149L 18.0147,34.4645C 16.0621,36.4171 16.0621,39.5829 18.0147,41.5355 Z M 31,49C 30,49 29.6048,48.8828 29.086,48.3641L 20.1361,39.4142C 19.355,38.6332 19.355,37.3669 20.1361,36.5858L 29.086,27.6362C 29.6048,27.1175 30,27 31,27.0001L 52,27.0001C 53.1046,27.0001 54,27.8955 54,29.0001L 54,47.0001C 54,48.1046 53.1046,49.0001 52,49.0001L 31,49 Z "/> </g>
                        </svg>
                    </button>
                    <button 
                        onClick={() => setIsReqInCheck(!isReqInCheck)}
                        className={isReqInCheck ? 'isInCheck active' : 'isInCheck'}
                    >
                            На проверке 
                            {inCheckCount !== 0 && <span style={{color: "red", fontWeight: "700"}}>{inCheckCount}</span>}
                    </button>
                    <div className="pag_panel">
                        <div className="pags">
                            <button onClick={() => setPageIndex(pageIndex > 1 ? (pageIndex - 1) : (pageIndex))}>Назад</button>
                            <span>{pageIndex}</span>
                            <button onClick={() => setPageIndex(Math.ceil((reqRowsCount / pageSize)) > pageIndex ? (pageIndex + 1) : (pageIndex))}>Вперед</button>
                        </div>
                        <div className="pagSizes">
                            <button onClick={() => setPageSize(5)} className={pageSize === 5 ? 'selected' : ''}>5</button>
                            <button onClick={() => setPageSize(10)} className={pageSize === 10 ? 'selected' : ''}>10</button>
                            <button onClick={() => setPageSize(15)} className={pageSize === 15 ? 'selected' : ''}>15</button>
                        </div>
                    </div>
                </div>
                <Paper sx={{ width: '100%', overflow: 'hidden', background: `${theme === 'light' ? 'white' : 'rgba(31, 38, 48, 1)'}` }}>
                    <TableContainer sx={{ maxHeight: "auto" }}>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                key={column.id}
                                align={column.align}
                                style={theme === 'light' ? {color: "black", background: "#DCDCDC", minWidth: column.minWidth} : {color: "white", background: "rgb(22, 29, 38)", minWidth: column.minWidth}}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody style={{maxHeight: 'auto'}}>
                            {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.uuid_info} style={indexOfChanged.includes(index) ? {background: "rgba(255, 87, 87, 0.31)"} : {}}>
                                    {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align} style={theme === 'light' ? {color: "black"} : {color: "white"}}>
                                        {column.id === 'status' 
                                            ?
                                            column.formatStatus(value)
                                            : 
                                            (column.id === 'actions_column' && row.status !== 'success' && row.status !== 'success_by_support' && (isReqInCheck === true)) 
                                            ? 
                                            <div className="activeAndInCheckButtons">
                                                <button className='closeRequestButton' onClick={() => {setModalIsOpen1(true); setSelectedRequestPrice(row.rur_info); setSelectedRequestPk(findRequestsPkSubstring(row.actions_column, 'data-pk="', '"'))}}>Закрыть заявку</button> 
                                                <button className='closeRequestButton' onClick={() => {setModalIsOpen2(true); setSelectedRequestPrice(row.rur_info); setSelectedRequestPk(findRequestsPkSubstring(row.actions_column, 'data-pk="', '"'))}}>Отменить заявку</button> 
                                                <button className='closeRequestButton' onClick={() => {setModalIsOpen3(true); setSelectedRequestPrice(row.rur_info); setSelectedRequestPk(findRequestsPkSubstring(row.actions_column, 'data-pk="', '"'))}}>Закрыть по видео</button> 
                                            </div>
                                            :
                                            (column.id === 'actions_column'  && row.status !== 'success' && row.status !== 'success_by_support' && (isReqInCheck !== true)) 
                                            ?
                                                <button className='closeRequestButton' onClick={() => {setModalIsOpen1(true); setSelectedRequestPrice(row.rur_info); setSelectedRequestPk(findRequestsPkSubstring(row.actions_column, 'data-pk="', '"'))}}>Закрыть заявку</button> 
                                            :
                                            column.format(value)
                                        }
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={theme === 'light' ? {color: 'black', opacity: '1', display: 'none'} : {color: 'white', opacity: '1', display: 'none'}}
                    />
                </Paper>
                <ConfirmationModal
                    isOpen={modalIsOpen1} 
                    onRequestClose={() => setModalIsOpen1(false)} 
                    onConfirm={closeRequest} 
                    price={selectedRequestPrice} 
                    textLabel="Подтверждение закрытия заявки"
                    textAction="закрыть"
                />
                <ConfirmationModal
                    isOpen={modalIsOpen2} 
                    onRequestClose={() => setModalIsOpen2(false)} 
                    onConfirm={cancelRequest} 
                    price={selectedRequestPrice} 
                    textLabel="Подтверждение отмены заявки"
                    textAction="отменить"
                />
                <ConfirmationModal
                    isOpen={modalIsOpen3} 
                    onRequestClose={() => setModalIsOpen3(false)} 
                    onConfirm={closeRequestByVideo} 
                    price={selectedRequestPrice} 
                    textLabel="Подтверждение закрытия заявки по видео"
                    textAction="закрыть"
                />
            </div>
        </div>
    )
}
