import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createSlice } from '@reduxjs/toolkit';

// Начальное состояние
const initialState = {
  API_URL: '/react_admin/api/' , // '/react_admin/api/'
  API_URL_NO_RA: '/',  //   '/'
  APP_THEME: 'dark',
  REQUISITE_DATA_TO_EDIT: {},
  USER_CODE: '',
  USER_ID: null,
  USER_ADD_BALANCE_WALLET: '',
  SHOW_SIDEBAR: false,
  IS_ACTIVE_USER: false,
  USER_ROLE: '',
  USER_ROLE_INCLUDE_BOOST: '',
  PROVIDER: '',
  USER_NAME: '',
  ADD_BALANCE_LAST_TIME: '',
  BOOST_ACCOUNT_NEXT_TIME: '',
};

// Создание slice
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppTheme: (state, action) => {
      state.APP_THEME = action.payload;
    },
    setDataOfReqToEdit: (state, action) => {
      state.REQUISITE_DATA_TO_EDIT = action.payload;
    },
    setUserCode: (state, action) => {
      state.USER_CODE = action.payload;
    },
    setUserID: (state, action) => {
      state.USER_ID = action.payload;
    },
    setUserAddBalanceWallet: (state, action) => {
      state.USER_ADD_BALANCE_WALLET = action.payload;
    },
    setShowSidebar: (state, action) => {
      state.SHOW_SIDEBAR = action.payload;
    },
    setIsUserActive: (state, action) => {
      state.IS_ACTIVE_USER = action.payload;
    },
    setUserRole: (state, action) => {
      state.USER_ROLE = action.payload;
    },
    setProvider: (state, action) => {
      state.PROVIDER = action.payload; 
    },
    setUserName: (state, action) => {
      state.USER_NAME = action.payload; 
    },
    setUserRoleIncludeBoost: (state, action) => {
      state.USER_ROLE_INCLUDE_BOOST = action.payload; 
    },
    setLastTimeOfAddBalance: (state, action) => {
      state.ADD_BALANCE_LAST_TIME = action.payload; 
    },
    setNextTimeOfBoostAccount: (state, action) => {
      state.BOOST_ACCOUNT_NEXT_TIME = action.payload; 
    },
  }
});

// Экспортируем экшены
export const {
  setAppTheme,
  setDataOfReqToEdit,
  setUserCode,
  setUserID,
  setUserAddBalanceWallet,
  setShowSidebar,
  setIsUserActive,
  setUserRole,
  setProvider,
  setUserName,
  setUserRoleIncludeBoost,
  setLastTimeOfAddBalance,
  setNextTimeOfBoostAccount
} = appSlice.actions;

// Конфигурация redux-persist
const persistConfig = {
  key: 'root',
  storage,
};

// Создаём persistReducer
const persistedReducer = persistReducer(persistConfig, appSlice.reducer);

// Создаём store
const store = configureStore({
  reducer: persistedReducer,
});

// Экспортируем store по умолчанию
export default store;

// Экспортируем persistor
export const persistor = persistStore(store);
