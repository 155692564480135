import Sidebar from '../../components/Sidebar/Sidebar';
import { useState, useEffect } from 'react';
import './Requisites.scss'; 
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import UserInfo from '../../components/UserInfo/UserInfo';
import { format, formatDate } from 'date-fns';
import BtnGroupHeader from '../../components/BtnGroupHeader/BtnGroupHeader';
import { useSelector, useDispatch } from 'react-redux';
import { setDataOfReqToEdit } from '../../storeRedux';
import Alert from '@mui/material/Alert';
import { it } from 'date-fns/locale';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export default function Requisites() {

    localStorage.setItem('page', '/requisites');

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.APP_THEME);
    const api_url = useSelector((state) => state.API_URL);
    const show_sidebar = useSelector((state) => state.SHOW_SIDEBAR);
    const user_id = useSelector((state) => state.USER_ID);
    const data_to_edit = useSelector((state) => state.REQUISITE_DATA_TO_EDIT);

    const navigate = useNavigate();
    const jwtToken = localStorage.getItem('accessToken');

    // start of states

    const [requisitesData, setRequisitesData] = useState([]);
    const [columns, setColumns] = useState([]);

    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [statusValues, setStatusValues] = useState([]);
    const [autoClose, setAutoClose] = useState('');
    const [searchNumber, setSearchNumber] = useState('');

    const [status, setStatus] = useState('1');
    const [canAutoclose, setCanAutoclose] = useState(true);
    const [paymentType, setPaymentType] = useState('1');
    const [credentials, setCredentials] = useState('');
    const [credentials_limit, setCredentials_limit] = useState('');
    const [credential_max_rur, setCredential_max_rur] = useState('');
    const [credential_min_rur, setCredential_min_rur] = useState('');
    const [onOneRequest, setOnOneRequest] = useState(false);
    const [comment, setComment] = useState('');
    const [maxReqPerMinute, setMaxReqPerMinute] = useState('');
    const [diapazonForReq, setDiapazonForReq] = useState(60);
    const [maxSuccessPerDay, setMaxSuccessPerDay] = useState('');

    const updateDiapazon = (e, data) => {
        setDiapazonForReq(data);
    };

    const [IS_CREATING_REQUISITE, SET_IS_CREATING_REQUISITES] = useState(false);
    const [IS_EDITING_REQUISITE, SET_IS_EDITING_REQUISITE] = useState(false);

    const [editingCredentials, setEditingCredentials] = useState('');
    const [editingLimit, setEditingLimit] = useState('');
    const [editingStatus, setEditingStatus] = useState('');
    const [editingComment, setEditingComment] = useState('');
    const [editingAutoClose, setEditingAutoClose] = useState(false);
    const [editingMinAmount, setEditingMinAmount] = useState('');
    const [editingMaxAmount, setEditingMaxAmount] = useState('');
    const [editingPaymentMethod, setEditingPaymentMethod] = useState('');
    const [editingMaxReqPerMinute, setEditingMaxReqPerMinute] = useState('');
    const [edititngDiapazonForReq, setEdititngDiapazonForReq] = useState(1);
    const [editingMaxSuccessPerDay, setEditingMaxSuccessPerDay] = useState('');

    const [errorFetchingRequisities, setErrorFetchingRequisities] = useState(false); // стейт для вывода алерта об ошибки запроса данных реквизитов

    const [errorSubmit, setErrorSubmit] = useState(false);
    const [errorSubmitText, setErrorSubmitText] = useState('');

    const [successSubmit, setSuccessSubmit] = useState(false);

    const [pageSize, setPageSize] = useState(6);
    const [pageIndex, setPageIndex] = useState(1);
    const [reqRowsCount, setReqRowsCount] = useState(0);

    // end of states

    const setEditDataInfo = (some_data) => {
        const newData = some_data;
        dispatch(setDataOfReqToEdit(newData));
    };

    const statusesDefault = {
        "1" : "активный",
        "2" : "ожидание",
        "7" : "недостаточно баланса",
        "34" : "лимит по карте(временный)",
        "4" : "лимит по карте",
        "5" : "архив",
        "6" : "блок",
        "8" : "Максимум заявок за указанный диапазон",
        "9" : "Лимит успешных в сутки",
    };

    const handleStatusClick = (status, value) => {
        if (selectedStatuses.includes(status)) {
            // Убираем статус из selectedStatuses и его значение из statusValues
            setSelectedStatuses(selectedStatuses.filter(item => item !== status));
            setStatusValues(statusValues.filter(item => item !== value));
        } else {
            // Добавляем статус в selectedStatuses и его значение в statusValues
            setSelectedStatuses([...selectedStatuses, status]);
            setStatusValues([...statusValues, value]);
        }
    };

    useEffect(() => {
        if (!jwtToken) {
            navigate('/auth');
            localStorage.clear();
        }
    }, []);

    const generateColumns = (data) => {
        if (data.length > 0) {
            const firstItem = data[0];
            const generatedColumns = Object.keys(firstItem).slice(1).map(key => ({
                id: key,
                label: firstItem[key]?.label || key,
                minWidth: 170,
                align: 'left',
                format: (value) => <div dangerouslySetInnerHTML={{ __html: value }} />,
                formatStatus: (value) => <div className={`reqStatus reqStatus${value}`}>{statusesDefault[value]}</div>,
                formatBool: (value) => {
                    if (value) {
                        return "Да"
                    } 
                    return "Нет"
                },
                formatDate: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss')
            }));
            setColumns(generatedColumns);
        }
    };

    useEffect(() => {
        const jwtToken = localStorage.getItem('accessToken');
        setSuccessSubmit(false);
        const getRequestsData = async () => {
          try {
            const response = await fetch(api_url + `hub/managercredentials/info/?page=${pageIndex}&page_size=${pageSize}&status__in=${statusValues.join(',')}&search=${searchNumber}&can_autoclose=${autoClose}`,  {
              headers: {
                'Authorization': `JWT ${jwtToken}`,
                'Content-Type': 'application/json'
              }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    // Обработка ошибки 401
                    localStorage.clear();
                    navigate('/auth');
                }
            }
            const data = await response.json();
            setRequisitesData(data.results);
            generateColumns(data.results);
            setReqRowsCount(data.count);
          } catch (error) {
            console.error('Error fetching data:', error);
            setErrorFetchingRequisities(true);
          }
        };
    
        getRequestsData();

        const intervalId = setInterval(getRequestsData, 20000);

        return () => clearInterval(intervalId);
    }, [successSubmit, pageIndex, pageSize, statusValues, searchNumber, autoClose]);

    function createDataFromRequest(request) {
        let data = {};
        for (let key in request) {
            if (request.hasOwnProperty(key)) {
                data[key] = request[key].value;
            }
        }
        return data;
    }

    const handleClearFilters = () => {
        setSelectedStatuses([]);
        setStatusValues([]);
        setAutoClose('');
        setSearchNumber('');
    }

    const fieldNames = {
        credentials: 'Реквизиты',
        credentials_limit: 'Лимит',
        min_rur: 'Минимальная сумма заявки',
        max_rur: 'Максимальная сумма заявки',
        max_requests_in_range: 'Максимальное кол-во заявок в минуту',
        __all__: 'Ошибка'
    };

    const handleSubmit = async () => {
        const data = {
            credentials,
            credentials_limit: parseInt(credentials_limit, 10),
            on_one_request: onOneRequest,
            status,
            comment,
            can_autoclose: canAutoclose,
            payment_type: paymentType,
            min_rur: parseInt(credential_min_rur, 10),
            max_rur: parseInt(credential_max_rur, 10),
            user: user_id,
            max_requests_in_range: maxReqPerMinute,
            range_in_minutes: diapazonForReq,
            max_success_requests_per_day: maxSuccessPerDay,
        };

        try {
            const response = await fetch(api_url + 'hub/managercredentials/', {
                method: 'POST',
                headers: {
                    'Authorization': `JWT ${jwtToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
    
            if (response.ok) {
                setSuccessSubmit(true);
                SET_IS_CREATING_REQUISITES(false);
            } else {
                if (response.status === 401) {
                    // Обработка ошибки 401
                    localStorage.clear();
                    navigate('/auth');
                } else if (response.status === 400) {
                    // Обработка ошибки 400
                    const errorData = await response.json();
                    console.error('Server response:', errorData);
    
                    // Преобразуем ошибки в читаемый формат
                    const formattedErrors = Object.entries(errorData)
                        .map(([key, messages]) => {
                            const fieldName = fieldNames[key] || key; // Используем перевод или оригинальный ключ
                            return `${fieldName} - ${messages.join(', ')}`;
                        })
                        .join('\n');
    
                    setErrorSubmit(true);
                    setErrorSubmitText(formattedErrors);
                } else {
                    setErrorSubmit(true);
                    setErrorSubmitText('Неизвестная ошибка');
                }
            }
        } catch (error) {
            console.error('Error submitting data:', error);
            setErrorSubmit(true);
            setErrorSubmitText('Ошибка при отправке данных на сервер');
        }
    };
    

    const toogleCreationOfRequisite = () => {
        SET_IS_CREATING_REQUISITES(!IS_CREATING_REQUISITE);
        setStatus('1');
        setCanAutoclose(true);
        setPaymentType('1');
        setCredentials('');
        setCredentials_limit('');
        setCredential_max_rur('');
        setCredential_min_rur('');
        setOnOneRequest(false);
        setComment('');
        setMaxReqPerMinute('');
    }

    function findRequisiteCredSubstring(inputString, start, end) {
        const startIndex = inputString.indexOf(start) + start.length;
        const endIndex = inputString.indexOf(end, startIndex);
    
        if (startIndex < start.length || endIndex === -1) {
            return 'null';
        }

        return String(inputString.substring(startIndex, endIndex));
    }

    function findRequisiteLimitSubstring(inputString, start, end) {
        const startIndex = inputString.indexOf(start) + start.length;
        const endIndex = inputString.indexOf(end, startIndex);
    
        if (startIndex < start.length || endIndex === -1) {
            return 'null';
        }

        return Number(inputString.substring(startIndex, endIndex).replace(/\s+/g, ''));
    }

    const handleStartToEdit = (item) => {
        SET_IS_EDITING_REQUISITE(true); 
        setEditDataInfo(item);
        setEditingCredentials(findRequisiteCredSubstring(item.credentials_info.value, '">', '</a>'));
        setEditingLimit(findRequisiteLimitSubstring(item.total_amount.value, 'Лимит: <b>', ' руб'));
        setEditingStatus(item.status.value);
        setEditingComment(item.comment.value);
        setEditingAutoClose(item.can_autoclose.value);
        setEditingMinAmount(item.min_rur.value);
        setEditingMaxAmount(item.max_rur.value);
        setEditingPaymentMethod(item.payment_type.value === 'ccard1' ? '1' : item.payment_type.value === 'SBP' ? '2' : '');
        setEditingMaxReqPerMinute(item.max_requests_in_range.value);
        setEdititngDiapazonForReq(item.range_in_minutes.value);
        setEditingMaxSuccessPerDay(item.max_success_requests_per_day.value)
    }

    const handleSaveEditing = async () => {
        const data = {
            credentials: editingCredentials,
            credentials_limit: parseInt(editingLimit, 10),
            // on_one_request: onOneRequest,
            status: editingStatus,
            comment: editingComment,
            can_autoclose: editingAutoClose,
            min_rur: parseInt(editingMinAmount, 10),
            max_rur: parseInt(editingMaxAmount, 10),
            payment_type: editingPaymentMethod,
            user: user_id,
            max_requests_in_range: editingMaxReqPerMinute,
            range_in_minutes: edititngDiapazonForReq,
            max_success_requests_per_day: editingMaxSuccessPerDay,
        };

        try {
            const response = await fetch(api_url + `hub/managercredentials/${data_to_edit.pk}` + '/', {
                method: 'PUT',
                headers: {
                    'Authorization': `JWT ${jwtToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                setSuccessSubmit(true);
            } else if (response.status === 400) {
                // Обработка ошибки 400
                const errorData = await response.json();
                console.error('Server response:', errorData);

                // Преобразуем ошибки в читаемый формат
                const formattedErrors = Object.entries(errorData)
                    .map(([key, messages]) => {
                        const fieldName = fieldNames[key] || key; // Используем перевод или оригинальный ключ
                        return `${fieldName} - ${messages.join(', ')}`;
                    })
                    .join('\n');

                setErrorSubmit(true);
                setErrorSubmitText(formattedErrors);
            } else {
                if (response.status === 401) {
                    // Обработка ошибки 401
                    localStorage.clear();
                    navigate('/auth');
                } else {
                    setErrorSubmit(true);
                }
                
            }
        } catch (error) {
            console.error('Error submitting data:', error);
            setErrorSubmit(true);
        }
    };

    return (
        <div className={theme === 'light' ? "Requisites" : "Requisites dark"}>
            {errorFetchingRequisities && <Alert style={{cursor: "pointer", position: "absolute", margin: "auto", top: "16px", left: "0", right: "0", width: "max-content", zIndex: "100"}} severity="error" onClose={() => setErrorFetchingRequisities(false)}>Ошибка получения данных о реквизитах</Alert>}
            {errorSubmit && (
                <Alert
                    style={{
                        cursor: "pointer",
                        position: "fixed",
                        margin: "auto",
                        top: "16px",
                        left: "0",
                        right: "0",
                        width: "max-content",
                        zIndex: "100"
                    }}
                    severity="error"
                    onClose={() => setErrorSubmit(false)}
                >
                    {errorSubmitText.split('\n').map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </Alert>
            )}
            {successSubmit && <Alert style={{cursor: "pointer", position: "absolute", margin: "auto", top: "16px", left: "0", right: "0", width: "max-content", zIndex: "100"}} severity="success" onClose={() => setSuccessSubmit(false)}>Данные успешно отправлены</Alert>}
            <Sidebar/>
            <div className={show_sidebar ? "content closed" : "content"}>
                {IS_EDITING_REQUISITE && <div className="editReqPopUp">
                    <div className="row">
                        <h2>Редактирование</h2>
                    </div>
                    <div className="row">
                        <h6>Реквизиты</h6>
                        <input 
                            type="text" 
                            value={editingCredentials}
                            readOnly
                        />
                    </div>
                    <div className="row">
                        <h6>Лимит</h6>
                        <input 
                            type="text" 
                            value={editingLimit}
                            onChange={(e) => setEditingLimit(e.target.value.replace(/\D/, ''))} 
                        />
                    </div>
                    <div className="row">
                        <h6>Статус</h6>
                        <div className="statuses">
                            {[['1', 'Активно'], ['2', 'Ожидание'], ['7', 'Недостаточно баланса'], 
                              ['34', 'Временный лимит по карте'], ['4', 'Лимит по карте'], 
                              ['5', 'Архив'], ['6', 'Блок'], ['8', 'Максимум заявок за указанный диапазон'], ['9', 'Лимит успешных в сутки']].map((s) => (
                                <button 
                                    key={s[0]} 
                                    onClick={() => setEditingStatus(s[0])}
                                    className={editingStatus === s[0] ? 'selected' : ''}
                                >
                                    {s[1]}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="row">
                        <h6>Подсказка</h6>
                        <input 
                            type="text" 
                            value={editingComment}
                            onChange={(e) => setEditingComment(e.target.value)} 
                        />
                    </div>
                    <div className="row">
                        <h6>Количество успешных переводов в сутки</h6>
                        <input 
                            type="text" 
                            value={editingMaxSuccessPerDay}
                            onChange={(e) => setEditingMaxSuccessPerDay(e.target.value.replace(/\D/, ''))} 
                        />
                    </div>
                    <div className="row">
                        <h6>Максимальное количество заявок в диапазон</h6>
                        <input 
                            type="text" 
                            value={editingMaxReqPerMinute}
                            onChange={(e) => setEditingMaxReqPerMinute(e.target.value.replace(/\D/, ''))} 
                        />
                    </div>
                    <div className="row" style={{marginBottom: '36px'}}>
                        <h6>Диапазон макс. заявок в минутах</h6>
                    </div>
                    <Slider
                        value={edititngDiapazonForReq}
                        onChange={(e) => setEdititngDiapazonForReq(e.target.value)}
                        valueLabelDisplay="on"
                        marks={[
                            {
                                value: 1,
                                label: '1'
                            },
                            {
                                value: 60,
                                label: '60'
                            }
                        ]}
                        min={1}
                        max={60}
                        step={1}
                        style={{
                            width: '100%', 
                            color: theme === 'dark' ? '#2787F5' : 'rgb(255, 210, 86)'
                        }}
                    />
                    <div className="row">
                        <h6>Автозакрытие</h6>
                        <div className="row_select_payment_method">
                            <button 
                                onClick={() => setEditingAutoClose(true)}
                                className={editingAutoClose ? 'selected' : ''}
                            >
                                Да
                            </button>
                            <button 
                                onClick={() => setEditingAutoClose(false)}
                                className={!editingAutoClose ? 'selected' : ''}
                            >
                                Нет
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <h6>Минимальная сумма заявки</h6>
                        <input 
                            type="text" 
                            value={editingMinAmount}
                            onChange={(e) => setEditingMinAmount(e.target.value.replace(/\D/, ''))} 
                        />
                    </div>
                    <div className="row">
                        <h6>Максимальная сумма заявки</h6>
                        <input 
                            type="text" 
                            value={editingMaxAmount}
                            onChange={(e) => setEditingMaxAmount(e.target.value.replace(/\D/, ''))} 
                        />
                    </div>
                    <div className="row">
                        <h6>Метод оплаты</h6>
                        <div className="statuses">
                            {[['Оплата по номеру карты', '1', 'ccard1'], ['Оплата по СБП', '2', 'SBP']].map((b) => (
                                <button 
                                    key={b[1]}
                                    className={editingPaymentMethod === b[1] ? 'selected' : ''}
                                >
                                    {b[0]}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="buttons">
                        <button className='red' onClick={() => SET_IS_EDITING_REQUISITE(false)}>Отменить</button>
                        <button className='green' onClick={() => {SET_IS_EDITING_REQUISITE(false); handleSaveEditing()}}>Сохранить изменения</button>
                    </div>
                </div>}
                <div className="header">
                    <h2 className='urbanist-500'>Реквизиты<button className={IS_CREATING_REQUISITE ? 'addReq red' : 'addReq'} onClick={toogleCreationOfRequisite}>{!IS_CREATING_REQUISITE ? 'Добавить реквизит' : 'Отменить создание'}</button></h2>
                    <UserInfo/>
                    <BtnGroupHeader/>
                </div>
                <div className="filters">
                    <div className="statuses">
                        {[
                            { label: 'Активно', value: '1' },
                            { label: 'Ожидание', value: '2' },
                            { label: 'Недостаточно баланса', value: '3' },
                            { label: 'Временный лимит по карте', value: '34' },
                            { label: 'Лимит по карте', value: '4' },
                            { label: 'Архив', value: '5' },
                            { label: 'Блок', value: '6' },
                            { label: 'Максимум заявок за указанный диапазон', value: '8' },
                            { label: 'Лимит успешных в сутки', value: '9' }
                        ].map(status => (
                            <button
                                key={status.label}
                                className={(selectedStatuses.includes(status.label) && theme === 'light') ? 'selected' : (selectedStatuses.includes(status.label) && theme === 'dark') ? 'darkselected' : ''}
                                onClick={() => handleStatusClick(status.label, status.value)}
                            >
                                {status.label}
                            </button>
                        ))}
                    </div>
                    <div className="dop_filters">
                        <div className="auto">
                            <span>Автозакрытие:</span>
                            <div className="buttons">
                                <button 
                                    className={(autoClose !== '' && autoClose && theme === 'light') ? 'selected' : (autoClose !== '' && autoClose && theme === 'dark') ? 'darkselected' : ''} 
                                    onClick={() => setAutoClose(autoClose === true ? '' : true)}
                                >
                                    Да
                                </button>
                                <button 
                                    className={(autoClose !== '' && !autoClose && theme === 'light') ? 'selected' : (autoClose !== '' && !autoClose && theme === 'dark') ? 'darkselected' : ''} 
                                    onClick={() => setAutoClose(autoClose === false ? '' : false)}
                                >
                                    Нет
                                </button>
                            </div>
                        </div>
                        <input 
                            type="text" 
                            placeholder='Поиск по реквизитам и подсказке'
                            value={searchNumber}
                            onChange={(e) => setSearchNumber(e.target.value)}
                        />
                        <button className='clear_filteres' onClick={handleClearFilters}>
                            <span>Сбросить фильтры</span>
                            <svg width="28" height="28" viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg" version="1.1" baseProfile="full" enableBackground="new 0 0 76.00 76.00" fill="#000000">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"/>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>
                                <g id="SVGRepo_iconCarrier"> <path fill="#000000" fillOpacity="1" strokeWidth="0.2" strokeLinejoin="round" d="M 47.5282,42.9497L 42.5784,38L 47.5282,33.0502L 44.9497,30.4718L 40,35.4216L 35.0502,30.4718L 32.4718,33.0502L 37.4216,38L 32.4718,42.9497L 35.0502,45.5282L 40,40.5784L 44.9497,45.5282L 47.5282,42.9497 Z M 18.0147,41.5355L 26.9646,50.4854C 28.0683,51.589 29,52 31,52L 52,52C 54.7614,52 57,49.7614 57,47L 57,29C 57,26.2386 54.7614,24 52,24L 31,24C 29,24 28.0683,24.4113 26.9646,25.5149L 18.0147,34.4645C 16.0621,36.4171 16.0621,39.5829 18.0147,41.5355 Z M 31,49C 30,49 29.6048,48.8828 29.086,48.3641L 20.1361,39.4142C 19.355,38.6332 19.355,37.3669 20.1361,36.5858L 29.086,27.6362C 29.6048,27.1175 30,27 31,27.0001L 52,27.0001C 53.1046,27.0001 54,27.8955 54,29.0001L 54,47.0001C 54,48.1046 53.1046,49.0001 52,49.0001L 31,49 Z "/> </g>
                            </svg>
                        </button>
                        <div className="pag_panel">
                            <div className="pags">
                                <button onClick={() => setPageIndex(pageIndex > 1 ? (pageIndex - 1) : (pageIndex))}>Назад</button>
                                <span>{pageIndex}</span>
                                <button onClick={() => setPageIndex(Math.ceil((reqRowsCount / pageSize)) > pageIndex ? (pageIndex + 1) : (pageIndex))}>Вперед</button>
                            </div>
                            <div className="pagSizes">
                                <button onClick={() => setPageSize(6)} className={pageSize === 6 ? 'selected' : ''}>6</button>
                                <button onClick={() => setPageSize(9)} className={pageSize === 9 ? 'selected' : ''}>9</button>
                                <button onClick={() => setPageSize(12)} className={pageSize === 12 ? 'selected' : ''}>12</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="req_cards_wrapper">
                    <div className="req_cards">
                        {IS_CREATING_REQUISITE && <div className="creating_req_card">
                            <div className="row">
                                <h6 style={credentials === '' ? {color: "#cf0000"} : {}}>Реквизиты</h6>
                                <input 
                                    type="text" 
                                    placeholder='Реквизиты' 
                                    value={credentials}
                                    onChange={(e) => { setCredentials(e.target.value) }}
                                />
                            </div>
                            <div className="row">
                                <h6 style={credentials_limit === '' ? {color: "#cf0000"} : {}}>Лимит</h6>
                                <input
                                    type="text" 
                                    placeholder='Лимит на реквизиты, руб (до 50.000.000)' 
                                    value={credentials_limit}
                                    // onChange={(e) => setCredentials_limit(e.target.value.replace(/\D/, ''))} // Только цифры
                                    onChange={(e) => {
                                        if (e.target.value.length <= 8 && e.target.value <= 50000000) {
                                            setCredentials_limit(e.target.value.replace(/\D/, ''));
                                        }
                                    }}
                                />
                            </div>
                            <div className="row">
                                <h6  style={credential_min_rur === '' ? {color: "#cf0000"} : {}}>Минимальная сумма заявки</h6>
                                <input 
                                    type="text" 
                                    placeholder='Минимальная сумма заявки (от 0)' 
                                    value={credential_min_rur}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 6 && e.target.value <= 100000) {
                                            setCredential_min_rur(e.target.value.replace(/\D/, ''));
                                        }
                                    }}
                                />
                            </div>
                            <div className="row">
                                <h6 style={credential_max_rur === '' ? {color: "#cf0000"} : {}}>Максимальная сумма заявки</h6>
                                <input 
                                    type="text" 
                                    placeholder='Максимальная сумма заявки (до 100.000)' 
                                    value={credential_max_rur}
                                    // onChange={(e) => setCredential_max_rur(e.target.value.replace(/\D/, ''))} // Только цифры
                                    onChange={(e) => {
                                        if (e.target.value.length <= 6 && e.target.value <= 100000) {
                                            setCredential_max_rur(e.target.value.replace(/\D/, ''));
                                        }
                                    }}
                                />
                            </div>
                            <div className="row">
                                <h6>Подсказка</h6>
                                <input 
                                    type="text" 
                                    placeholder='Подсказка' 
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </div>
                            <div className="row">
                                <h6 style={maxSuccessPerDay === '' ? {color: "#cf0000"} : {}}>Количество успешных переводов в сутки</h6>
                                <input 
                                    type="text" 
                                    placeholder='Если выставить 0, то не будет ограничения' 
                                    value={maxSuccessPerDay}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 22) {
                                            setMaxSuccessPerDay(e.target.value.replace(/\D/, ''));
                                        }
                                    }}
                                />
                            </div>
                            <div className="row">
                                <h6 style={maxReqPerMinute === '' ? {color: "#cf0000"} : {}}>Максимальное кол-во заявок в диапазон</h6>
                                <input 
                                    type="text" 
                                    placeholder='Если выставить 0, то не будет ограничения' 
                                    value={maxReqPerMinute}
                                    onChange={(e) => setMaxReqPerMinute(e.target.value)}
                                />
                            </div>
                            <div className="row" style={{marginBottom: '36px'}}>
                                <h6 style={maxReqPerMinute === '' ? {color: "#cf0000"} : {}}>Диапазон макс. заявок в минутах</h6>
                            </div>
                            <Slider
                                value={diapazonForReq}
                                onChange={updateDiapazon}
                                valueLabelDisplay="on"
                                marks={[
                                    {
                                        value: 1,
                                        label: '1'
                                    },
                                    {
                                        value: 60,
                                        label: '60'
                                    }
                                ]}
                                min={1}
                                max={60}
                                step={1}
                                style={{
                                    width: '50%', 
                                    color: theme === 'dark' ? '#2787F5' : 'rgb(255, 210, 86)'
                                }}
                            />
                            <div className="row">
                                <h6>Метод оплаты</h6>
                                <div className="statuses">
                                    {[['Оплата по номеру карты', '1'], ['Оплата по СБП', '2']].map((b) => (
                                        <button 
                                            key={b[0]} 
                                            className={paymentType === b[1] ? 'selected' : ''} 
                                            onClick={() => setPaymentType(b[1])}
                                        >
                                            {b[0]}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="row">
                                <h6>Статус</h6>
                                <div className="statuses">
                                    {[['1', 'Активно'], ['2', 'Ожидание'], ['7', 'Недостаточно баланса'], 
                                      ['34', 'Временный лимит по карте'], ['4', 'Лимит по карте'], 
                                      ['5', 'Архив'], ['6', 'Блок'], ['8', 'Максимум заявок за указанный диапазон'], ['9', 'Лимит успешных в сутки']].map((s) => (
                                        <button 
                                            key={s[0]} 
                                            className={status === s[0] ? 'selected' : ''} 
                                            onClick={() => setStatus(s[0])}
                                        >
                                            {s[1]}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="row">
                                <h6>Автозакрытие</h6>
                                <div className="row_select_payment_method">
                                    <button onClick={() => setCanAutoclose(true)} className={canAutoclose === true ? 'selected' : ''}>Да</button>
                                    <button onClick={() => setCanAutoclose(false)} className={canAutoclose === false ? 'selected' : ''}>Нет</button>
                                </div>
                            </div>
                            <div className="buttons">
                                <button className='green' onClick={handleSubmit}>Добавить</button>
                                <button className='red' onClick={toogleCreationOfRequisite}>Отменить</button>
                            </div>
                        </div>}
                        {requisitesData.map((item, index) => (
                            <div className="card_req" key={index}>
                                <div className={`reqStatus reqStatus${item?.status?.value}`}>
                                    {statusesDefault[item?.status?.value]}
                                    </div>
                                <div className="top">
                                    <div className="row">
                                        <span>{findRequisiteCredSubstring(item?.user_info?.value, "", "<br>")}</span>
                                        <span>{findRequisiteCredSubstring(item?.credentials_info?.value, '">', '</a>')}</span>
                                    </div>
                                    <div className="row">
                                        <span>{item?.max_success_requests_per_day?.label}</span>
                                        <span>{item?.max_success_requests_per_day?.value}</span>
                                    </div>
                                    <div className="row">
                                        <span>мин. {item?.min_rur?.value} руб. / макс. {item?.max_rur?.value} руб.</span>
                                        <span>{item?.kind?.value} {item?.payment_type?.value}</span>
                                    </div>
                                    <div className="row">
                                        <span>{item?.can_autoclose?.value ? 'Автовыключение вкл.' : 'Автовыключение выкл.'}</span>
                                        <span>{item?.max_requests_in_range?.value === 0 ? 'Неограниченно заявок/мин' : `${item?.max_requests_in_range?.value} заявок / ${item?.range_in_minutes?.value} мин`}</span>
                                    </div>
                                </div>
                                <div className="middle">
                                    <span dangerouslySetInnerHTML={{ __html: item?.total_amount?.value }}></span>
                                    <div className="col">
                                        <span>{format(new Date(item?.created?.value), 'yyyy-MM-dd HH:mm:ss')}</span>
                                        <span>ред. {format(new Date(item?.modify?.value), 'yyyy-MM-dd HH:mm:ss')}</span>
                                    </div>
                                </div>
                                <div className="bottom">
                                    <span>{item?.comment?.value}</span>
                                    <button className='green' onClick={() => {!IS_EDITING_REQUISITE && handleStartToEdit(item)}}>Редактировать</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}