import './Withdrawals.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import UserInfo from '../../components/UserInfo/UserInfo';
import BtnGroupHeader from '../../components/BtnGroupHeader/BtnGroupHeader';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';

export default function Withdrawals() {

    localStorage.setItem('page', '/withdrawals');

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.APP_THEME);
    const show_sidebar = useSelector((state) => state.SHOW_SIDEBAR);
    const api_url = useSelector((state) => state.API_URL);
    const provider = useSelector((state) => state.PROVIDER);
    
    const jwtToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();

    const [withdrawalsData, setWithdrawalsData] = useState({});

    const [pageSize, setPageSize] = useState(4);
    const [pageIndex, setPageIndex] = useState(1);

    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState('');
    const [comment, setComment] = useState('');

    const [isWithCreating, setIsWithCreating] = useState(false);

    const api_url_no_ra = useSelector((state) => state.API_URL_NO_RA);

    const [goodClose, setGoodClose] = useState(false);

    const [successCreate, setSuccessCreate] = useState(false);
    const [errorCreate, setErrorCreate] = useState(false);
    const [errorCreateText, setErrorCreateText] = useState('');

    const statusMapping = {
        created: 'Создана',
        waiting: 'Ожидает подтверждения',
        canceled: 'Отменена',
        completed: 'Завершена'
    };

    // Функция для получения перевода статуса
    function translateStatus(status) {
        // Приводим статус к нижнему регистру и ищем в маппинге
        return statusMapping[status.toLowerCase()] || 'Неизвестный статус';
    }

    useEffect(() => {
        if (!jwtToken) {
            navigate('/auth');
            localStorage.clear();
        }
    }, []);

    useEffect(() => {
        setPageIndex(1);
    }, [pageSize]); // спорная реализация - проверить после финала

    useEffect(() => {
        const jwtToken = localStorage.getItem('accessToken');
        const fetchWithdrawalsData = async () => {
          try {
            const response = await fetch(api_url + `hub/withdrawrequest/info/?page=${pageIndex}&page_size=${pageSize}`, {
              method: 'GET',
              headers: {
                'Authorization': `JWT ${jwtToken}`,
                'Content-Type': 'application/json',
              },
            });
    
            if (!response.ok) {
              throw new Error(`Ошибка запроса: ${response.status}`); // Обработка ошибки ответа
            }
    
            const data = await response.json(); // Конвертация ответа в JSON
            setWithdrawalsData(data); // Сохранение данных в стейт
          } catch (error) {
            console.error('Ошибка при получении данных:', error.message);
          }
        };
    
        fetchWithdrawalsData();
      }, [pageIndex, pageSize, goodClose, successCreate]);

    function formatDate(dateString) {
        // Создаем объект Date из строки
        const date = new Date(dateString);

        // Получаем необходимые части даты
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        // Формируем итоговую строку
        return `[ ${hours}:${minutes} ] ${year}/${month}/${day}`;
    }

    const handleSubmit = async () => {
        // Проверка на заполнение полей
        if (!address || !amount || !provider) {
            setErrorCreate(true);
            setErrorCreateText('Пожалуйста, заполните все обязательные поля.');
            return;
        }

        const requestBody = {
            amount: parseFloat(amount), // Преобразуем строку в число
            address: address,
            provider: provider,
            comment: comment
        };

        try {
            const response = await fetch(`${api_url}hub/withdrawrequest/`, {
                method: 'POST',
                headers: {
                    'Authorization': `JWT ${jwtToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            const result = await response.json();

            if (response.ok) {
                setSuccessCreate(true);
                setAddress('');
                setAmount('');
                setComment('');
                setIsWithCreating(false);
            } else {
                setErrorCreate(true);
                setErrorCreateText(`Ошибка: ${result.detail || 'Не удалось создать запрос.'}`);
            }
        } catch (error) {
            setErrorCreate(true);
            setErrorCreateText('Произошла ошибка. Пожалуйста, попробуйте снова.');
        }
    };

    async function sendPostRequest(pk) {
        const jwtToken = localStorage.getItem('accessToken');
    
        if (!jwtToken) {
            return;
        }
    
        try {
            const url = `${api_url_no_ra}api/v3/withdrawrequests/approve/${pk}/`;
            const headers = {
                'Authorization': `JWT ${jwtToken}`,
                'Content-Type': 'application/json',
            };
    
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
            });
    
            if (response.ok) {
                const responseData = await response.json();
                setGoodClose(true);
                return responseData;
            } else {
                const errorData = await response.json();
                return { error: true, data: errorData };
            }
        } catch (error) {
            return { error: true, data: error };
        }
    }

    return (
        <div className={theme === 'light' ? "Withdrawals" : "Withdrawals dark"}>
            <Sidebar/>
            <div className={show_sidebar ? "content closed" : "content"}>
                {errorCreate && <Alert style={{cursor: "pointer", position: "fixed", margin: "auto", top: "16px", left: "0", right: "0", width: "max-content", zIndex: "100"}} severity="error" onClose={() => setErrorCreate(false)}>{errorCreateText}</Alert>}
                {successCreate && <Alert style={{cursor: "pointer", position: "fixed", margin: "auto", top: "16px", left: "0", right: "0", width: "max-content", zIndex: "100"}} severity="success" onClose={() => setSuccessCreate(false)}>Вывод успешно создан</Alert>}
                <div className="header">
                    <h2 className='urbanist-500'>
                        Вывод средств 
                        <div className={!isWithCreating ? "sq" : "sq red"} onClick={() => {setIsWithCreating(!isWithCreating); setAddress(''); setAmount(''); setComment('');}}>
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 12H20M12 4V20" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        </div>
                    </h2>
                    <UserInfo/>
                    <BtnGroupHeader/>
                </div>
                <div className="filters">
                    <div className="pag_panel">
                        <div className="pags">
                            <button onClick={() => setPageIndex(pageIndex > 1 ? (pageIndex - 1) : (pageIndex))}>Назад</button>
                            <span>{pageIndex}</span>
                            <button onClick={() => setPageIndex(Math.ceil((withdrawalsData.count / pageSize)) > pageIndex ? (pageIndex + 1) : (pageIndex))}>Вперед</button>
                        </div>
                        <div className="pagSizes">
                            <button onClick={() => setPageSize(4)} className={pageSize === 4 ? 'selected' : ''}>4</button>
                            <button onClick={() => setPageSize(8)} className={pageSize === 8 ? 'selected' : ''}>8</button>
                            <button onClick={() => setPageSize(12)} className={pageSize === 12 ? 'selected' : ''}>12</button>
                        </div>
                    </div>
                </div>
                <div className="cards_wrapper">
                    {isWithCreating && <div className={theme === 'dark' ? "CardForWithdrawal dark" : "CardForWithdrawal"}>
                    <div className="title new">
                        <span>Добавить запрос</span>
                    </div>
                    <div className="info">
                        <div className="block">
                            <h4>Адрес вывода</h4>
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder="Введите адрес вывода"
                            />
                        </div>
                        <div className="block">
                            <h4>Сумма на вывод</h4>
                            <input
                                type="text"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                onKeyDown={(e) => {
                                    if (!/^[0-9.]$/.test(e.key) && e.key !== "Backspace") {
                                        e.preventDefault();
                                    }
                                }}
                                placeholder="Введите сумму"
                            />
                        </div>
                        <div className="block">
                            <h4>Комментарий</h4>
                            <input
                                type="text"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder="Необязательное поле"
                            />
                        </div>
                        <button onClick={handleSubmit}>Создать</button>
                    </div>
                </div>}
                    {withdrawalsData.results?.map((item, index) => (
                        <div className={theme === 'dark' ? "CardForWithdrawal dark" : "CardForWithdrawal"} key={index}>
                            {goodClose && <Alert style={{cursor: "pointer", position: "fixed", margin: "auto", top: "16px", left: "0", right: "0", width: "max-content", zIndex: "100"}} severity="success" onClose={() => setGoodClose(false)}>Поступление успешно подтверждено</Alert>}
                            <div className={`title ${item.status.value}`}>
                                <span>{translateStatus(item.status.value)}</span>
                            </div>
                            <div className="info">
                                <div className="block">
                                    <h4>ID заявки</h4>
                                    <span>{item.pk.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Адрес вывода</h4>
                                    <span>{item.address.value}</span>
                                </div>
                                <div className="block">
                                    <h4>Сумма на вывод</h4>
                                    <span>{`${item.amount.value} USDT`}</span>
                                </div>
                                <div className="block">
                                    <h4>Дата создания</h4>
                                    <span>{formatDate(item.created.value)}</span>
                                </div>
                                <button 
                                    style={item.confirm_button.value !== '-' ? {opacity: "1"} : {opacity: "0"}}
                                    onClick={() => {item.confirm_button.value !== '-' && sendPostRequest(item.pk.value)}}
                                >
                                    Подтвердить поступление
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}