import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const PrivateRoute = ({ children, allowedRoles }) => {
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.USER_ROLE); // Замените на актуальную структуру состояния
  const isAuthenticated = Boolean(userRole);

  useEffect(() => {
    // Перенаправление на страницу авторизации, если пользователь не авторизован
    if (!isAuthenticated || !allowedRoles.includes(userRole)) {
      localStorage.clear()
      navigate('/auth');
    }
  }, [isAuthenticated, userRole, allowedRoles, navigate]);

  // Рендерим дочерние элементы только если пользователь авторизован и имеет доступ
  if (!isAuthenticated || !allowedRoles.includes(userRole)) {
    return null; // Опционально можно вернуть индикатор загрузки или заглушку
  }

  return <>{children}</>;
};

export default PrivateRoute;
