import './Statistics.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import UserInfo from '../../components/UserInfo/UserInfo';
import BtnGroupHeader from '../../components/BtnGroupHeader/BtnGroupHeader';
import { useSelector, useDispatch } from 'react-redux';
import React, { PureComponent } from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function Statistics() {

    localStorage.setItem('page', '/statistics');

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.APP_THEME);
    const show_sidebar = useSelector((state) => state.SHOW_SIDEBAR);
    const api_url = useSelector((state) => state.API_URL_NO_RA);
    
    const jwtToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();

    const [tab, setTab] = useState(0);

    const [data1, setData1] = useState({
        "x-axis": [],
        "y-axis": []
    });
    const [data2, setData2] = useState({
        "x-axis": [],
        "y-axis": []
    });
    const [data3, setData3] = useState({
        "x-axis": [],
        "y-axis": []
    });

    useEffect(() => {
        if (!jwtToken) {
            navigate('/auth');
            localStorage.clear();
        }
    }, []);

    useEffect(() => {
        const jwtToken = localStorage.getItem('accessToken');
        const fetchData = async () => {
            try {
                const response = await fetch(`${api_url}api/v3/exchange_request/statistic-by-rur/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `JWT ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();

                setData1(result['statistic-by-rur']);
                setData2(result['statistic-by-usdt']); 
                setData3(result['statistic-exchange-request-conversion']);
            } catch (error) {
                console.error('Ошибка при получении данных:', error);
            }
        };

        fetchData();
    }, []);

    const formatedData1 = data1["x-axis"]?.map((date1, index) => ({
        name: date1,
        RUB: data1["y-axis"][index],
    }));

    const formatedData2 = data2["x-axis"]?.map((date2, index) => ({
        name: date2,
        USDT: data2["y-axis"][index],
    }));

    const transformData = (data) => {
        return data['x-axis'].map((name, index) => ({
            name: name,
            'успешно %': data['y-axis'][index],
            'отмена %': 1 - data['y-axis'][index]
        }));
    };

    const formatedData3 = transformData(data3);

    return (
        <div className={theme === 'light' ? "Statistics" : "Statistics dark"}>
            <Sidebar/>
            <div className={show_sidebar ? "content closed" : "content"}>
                <div className="header">
                    <h2 className='urbanist-500'>Статистика</h2>
                    <UserInfo/>
                    <BtnGroupHeader/>
                </div>
                <div className="btns">
                    <button onClick={() => setTab(0)} className={tab === 0 ? 'selected' : ''}>Оборот в рублях</button>
                    <button onClick={() => setTab(1)} className={tab === 1 ? 'selected' : ''}>Зачисления на баланс</button>
                    <button onClick={() => setTab(2)} className={tab === 2 ? 'selected' : ''}>Конверсия</button>
                </div>
                <div className="stats" style={theme === 'dark' ? {border: "1px solid #2787F5"} : {border: "1px solid rgb(255, 210, 86)"}}>
                    {tab === 0 && <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                        width={100}
                        height={100}
                        data={formatedData1}
                        margin={{
                            top: 24,
                            right: 24,
                            left: 0,
                            bottom: 0,
                        }}
                        style={theme === 'dark' ? {background: "#0A0A0A"} : {background: "#edeef0"}}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name"/>
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="RUB" stroke={theme === 'dark' ? "#2787F5" : "rgb(160, 72, 0)"} activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>}
                    {tab === 1 && <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                        width={100}
                        height={100}
                        data={formatedData2}
                        margin={{
                            top: 24,
                            right: 24,
                            left: 0,
                            bottom: 0,
                        }}
                        style={theme === 'dark' ? {background: "#0A0A0A"} : {background: "#edeef0"}}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name"/>
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="USDT" stroke={theme === 'dark' ? "#2787F5" : "rgb(160, 72, 0)"} activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>}
                    {tab === 2 && <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                        width={100}
                        height={100}
                        data={formatedData3}
                        margin={{
                            top: 24,
                            right: 24,
                            left: 0,
                            bottom: 0,
                        }}
                        style={theme === 'dark' ? {background: "#0A0A0A"} : {background: "#edeef0"}}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="успешно %" stackId="a" fill={theme === 'dark' ? "#2787F5" : ' rgb(155, 114, 0)'} />
                        <Bar dataKey="отмена %" stackId="a" fill={theme === 'dark' ? "#003357" : 'rgb(255, 210, 86)'} />
                        </BarChart>
                    </ResponsiveContainer>}
                </div>
            </div>
        </div>
    )
}