import './ChartsOp.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import UserInfo from '../../components/UserInfo/UserInfo';
import BtnGroupHeader from '../../components/BtnGroupHeader/BtnGroupHeader';
import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function ChartsOp() {
    localStorage.setItem('page', '/chartsop');

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.APP_THEME);
    const show_sidebar = useSelector((state) => state.SHOW_SIDEBAR);
    const api_url = useSelector((state) => state.API_URL_NO_RA);
    
    const jwtToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();

    const [tab, setTab] = useState(0);
    const [originalData, setOriginalData] = useState({});

    useEffect(() => {
        if (!jwtToken) {
            navigate('/auth');
            localStorage.clear();
        }
    }, [jwtToken, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api_url}api/v3/exchange_request/statistic-operator/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `JWT ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();
                console.log('Fetched Data:', result); // Debug the response
                setOriginalData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [api_url, jwtToken]);

    function transformCategoryData1(categoryData = {}, stats) {
        const transformedData = [];
        stats.forEach((stat) => {
            const xAxis = categoryData[stat]?.["x-axis"] || [];
            const yAxis = categoryData[stat]?.["y-axis"] || [];
            for (let i = 0; i < xAxis.length; i++) {
                const dataPoint = transformedData[i] || { name: xAxis[i] };
                dataPoint[stat] = yAxis[i] || 0;
                transformedData[i] = dataPoint;
            }
        });
        return transformedData.map((data) => ({
            name: data.name,
            'пополнения': data['statistic-user-balance-deposit'] || 0,
            'списания': data['statistic-exchange-request'] || 0,
        }));
    }

    function transformCategoryData2(categoryData = {}, stats) {
        const transformedData = [];
        stats.forEach((stat) => {
            const xAxis = categoryData[stat]?.["x-axis"] || [];
            const yAxis = categoryData[stat]?.["y-axis"] || [];
            for (let i = 0; i < xAxis.length; i++) {
                const dataPoint = transformedData[i] || { name: xAxis[i] };
                dataPoint[stat] = yAxis[i] || 0;
                transformedData[i] = dataPoint;
            }
        });
        return transformedData.map((data) => ({
            name: data.name,
            'оборот': data['statistic-exchange-request-success'] || 0,
            'споры': data['statistic-exchange-request-success-by-support'] || 0,
        }));
    }

    const transformData = (data = {}) => {
        const { "x-axis": xAxis = [], "y-axis": yAxis = [] } = data;
        return xAxis.map((time, index) => ({
            name: time,
            'успешно %': yAxis[index] * 100,
            'отмена %': 100 - yAxis[index] * 100,
        }));
    };

    const historyOfBalanceData = originalData["history-of-balance"]
        ? transformCategoryData1(originalData["history-of-balance"], [
            "statistic-user-balance-deposit", 
            "statistic-exchange-request",
        ])
        : [];

    const dailyTurnoverRurData = originalData["daily-turnover-rur"]
        ? transformCategoryData2(originalData["daily-turnover-rur"], [
            "statistic-exchange-request-success",
            "statistic-exchange-request-success-by-support",
        ])
        : [];

    const conversionData = originalData["daily-turnover-rur"]?.["statistic-exchange-request-conversion"];
    const transformedDataConverse = conversionData ? transformData(conversionData) : [];

    return (
        <div className={theme === 'light' ? "ChartsOp" : "ChartsOp dark"}>
            <Sidebar />
            <div className={show_sidebar ? "content closed" : "content"}>
                <div className="header">
                    <h2 className='urbanist-500'>Статистика</h2>
                    <UserInfo />
                    <BtnGroupHeader />
                </div>
                <div className="btns">
                    <button onClick={() => setTab(0)} className={tab === 0 ? 'selected' : ''}>История баланса USDT</button>
                    <button onClick={() => setTab(1)} className={tab === 1 ? 'selected' : ''}>Суточный оборот</button>
                    <button onClick={() => setTab(2)} className={tab === 2 ? 'selected' : ''}>Конверсия</button>
                </div>
                <div className="stats" style={theme === 'dark' ? {border: "1px solid #2787F5"} : {border: "1px solid rgb(255, 210, 86)"}}>
                    {tab === 0 && historyOfBalanceData.length > 0 && (
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={100}
                                height={100}
                                data={historyOfBalanceData}
                                margin={{
                                    top: 24,
                                    right: 24,
                                    left: 0,
                                    bottom: 0,
                                }}
                                style={theme === 'dark' ? {background: "#0A0A0A"} : {background: "#edeef0"}}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey='пополнения' strokeWidth={2} stroke={theme === 'dark' ? "rgb(21, 160, 0)" : "rgb(21, 160, 0)"} activeDot={{ r: 8 }} />
                                <Line type="monotone" dataKey='списания' strokeWidth={2} stroke={theme === 'dark' ? "rgb(33, 104, 255)" : "rgb(40, 0, 160)"} activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                    {tab === 1 && dailyTurnoverRurData.length > 0 && (
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={100}
                                height={100}
                                data={dailyTurnoverRurData}
                                margin={{
                                    top: 24,
                                    right: 24,
                                    left: 0,
                                    bottom: 0,
                                }}
                                style={theme === 'dark' ? {background: "#0A0A0A"} : {background: "#edeef0"}}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="оборот" strokeWidth={2} stroke={theme === 'dark' ? "rgb(202, 44, 146)" : "rgb(255, 0, 234)"} activeDot={{ r: 8 }} />
                                <Line type="monotone" dataKey="споры" strokeWidth={2} stroke={theme === 'dark' ? "#FF7F11" : "rgb(157, 97, 0)"} activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                    {tab === 2 && transformedDataConverse.length > 0 && (
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={100}
                                height={100}
                                data={transformedDataConverse}
                                margin={{
                                    top: 24,
                                    right: 24,
                                    left: 0,
                                    bottom: 0,
                                }}
                                style={theme === 'dark' ? {background: "#0A0A0A"} : {background: "#edeef0"}}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="успешно %" stackId="a" fill={theme === 'dark' ? "#2787F5" : ' rgb(155, 114, 0)'} />
                                <Bar dataKey="отмена %" stackId="a" fill={theme === 'dark' ? "#003357" : 'rgb(255, 210, 86)'} />
                            </BarChart>
                        </ResponsiveContainer>
                    )}
                </div>
            </div>
        </div>
    );
}
